<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <validation-observer
      :ref="`${form.id}-form`"
      v-slot="{ invalid }"
      v-if="canEdit"
    >
      <b-row align-v="end">
        <b-col cols="12">
          <div class="form-group">
            <validation-provider
              name="informacoesAdicionaisExam"
              :rules="{ required: true }"
            >
              <TextArea
                :value="fields.informacaoAdicional"
                id="informacoesAdicionaisExam"
                @input="fields.informacaoAdicional = $event"
              />
            </validation-provider>
          </div>
        </b-col>
      </b-row>
      <div class="edit-area">
        <div class="btn-container">
          <b-button
            variant="primary"
            block
            class="h-38 button"
            :disabled="invalid"
            @click="saveItem"
          >
            {{ editingIndex !== null ? 'Atualizar' : 'Adicionar' }}
          </b-button>
        </div>
      </div>
    </validation-observer>
    <b-row>
      <b-col>
        <table class="table-eyecare">
          <thead>
            <tr>
              <th class="w-50">Informação</th>
              <th>Profissional</th>
              <th>Data/Horário</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) of form.value" :key="index">
              <td>
                <perfect-scrollbar :options="{ suppressScrollX: true }">
                  <div class="info">
                    <span
                      class="pre-formatted"
                      v-html="value.informacaoAdicional"
                    />
                  </div>
                </perfect-scrollbar>
              </td>
              <td>
                {{ value.professional_name }}
              </td>
              <td>
                {{ getFormatedTimeStamp(value.updated_at) }}
              </td>
              <td class="text-right">
                <div
                  class="more-with-menu"
                  v-if="canEditInfo(value.professional_id)"
                >
                  <MoreVertical class="more-icon" />
                  <div class="menu">
                    <b-button
                      variant="link"
                      @click="editItem(value, index)"
                      :disabled="!canEdit"
                    >
                      Editar
                    </b-button>
                    <b-button
                      variant="link remove mb-0"
                      @click="removeItem(index)"
                      :disabled="!canEdit"
                    >
                      Remover
                    </b-button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getFormatedTimeStamp } from '@/utils/dateHelper'
import { getCurrentUser } from '@/utils/localStorageManager'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    TextArea: () => import('@/components/General/TextArea')
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.informacoesAdicionaisExam
    })
  },
  data() {
    return {
      user: getCurrentUser(),
      fields: this.getDefaultFields(),
      editingIndex: null
    }
  },
  methods: {
    getFormatedTimeStamp,
    ...mapActions('attendance/form', [
      'updateMedicalRecord',
      'destroyMedicalRecord'
    ]),
    ...mapActions('attendance/form/informacoesAdicionaisExam', [
      'handleFields',
      'handleProps'
    ]),
    getDefaultFields() {
      return {
        informacaoAdicional: null
      }
    },
    saveItem() {
      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      value.push({
        ...this.fields,
        professional_id: this.user.id,
        professional_name: this.user.name,
        created_at: this.moment(),
        updated_at: this.moment()
      })
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
      this.editingIndex = null
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    hideForm() {
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key, index) => {
        this.removeItem(index)
      })
    },
    canEditInfo(professionalId) {
      return this.canEdit && this.user.id === professionalId
    }
  }
}
</script>
<style lang="scss" scoped>
.button {
  height: 38px;
  margin-bottom: 24px;
}

.edit-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;

  .btn-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
    height: 45px;

    button {
      width: 150px;
      margin-left: 30px;
    }

    .remove {
      margin-top: 0.5rem;
      color: var(--states-error);
    }

    button:nth-child(2) {
      width: 350px;
    }
  }
}

.info {
  max-height: 200px !important;
  .pre-formatted {
    word-break: break-word;
    white-space: pre-wrap;
    -moz-white-space: pre-wrap;
  }
}
</style>
