import { render, staticRenderFns } from "./InformacoesAdicionaisExamForm.vue?vue&type=template&id=72f2152b&scoped=true"
import script from "./InformacoesAdicionaisExamForm.vue?vue&type=script&lang=js"
export * from "./InformacoesAdicionaisExamForm.vue?vue&type=script&lang=js"
import style0 from "./InformacoesAdicionaisExamForm.vue?vue&type=style&index=0&id=72f2152b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f2152b",
  null
  
)

export default component.exports